import jsonPath from "jsonpath";

const evaluateVisibility = (props) => {
  const { values, visibility, combinator = "or", tabID, recordDetail, userPermissions = [], userGroups = [] } = props
  const extractValue = (inputs, fieldOrPath) => {
    // Regular value extracts a value from an object property
    const regularValue = inputs?.[fieldOrPath]
    try {
      // Path value extracts a value using JSON path
      if (fieldOrPath.match(/^\$\./)) {
        return jsonPath.query(inputs, fieldOrPath)
      }
      return regularValue
    } catch {
      return regularValue
    }
  }
  const getFieldValue = (field) => {
    // TODO: Find out where this .ohs prop is defined
    const ohsValues = Array.isArray(values?.ohs) ? values.ohs : values;
    // In detail mode, record details are stored in the first observation array
    const detailFieldValue = extractValue(ohsValues?.[0], field)
    let fieldValueInTab = extractValue(ohsValues?.[tabID - 1], field);

    // TODO: Find out where .name prop is defined
    // Get field value in tab from either the name property or its own value
    fieldValueInTab = fieldValueInTab?.name !== undefined
      ? fieldValueInTab?.name
      : fieldValueInTab;

    // If the field value in tab is undefined, it must be part of the root values
    const fieldValueInRoot = extractValue(values, field)

    // field value in a specific tab
    if (fieldValueInTab !== undefined) {
      return fieldValueInTab
      // field value from record details
    } else if (detailFieldValue !== undefined) {
      return detailFieldValue
    }
    // field value from main details
    return fieldValueInRoot
  };

  // Handle element visibility by other fields' value
  const isVisible = () => {
    if (visibility?.length > 0) {
      // By default, visibility are chained by OR operator (.some)
      // TODO: handle other chain, such as AND operator (.every)
      const chain = combinator === 'or' ? 'some' : 'every'
      return visibility[chain]((rule) => {
        const { field, jsonPath, value, operator, stage, permissions = [], stage_user_group } = rule;

        // If the rule contains a stage value, compare the value with
        // the record detail's status (i.e. data.status is one of the stage value)
        if (stage?.length > 0) {
          let recordStatus = recordDetail?.status || 'create'
          return stage.includes(recordStatus)
        }

        // If the rule contains a stage_user_group value, compare stage id with the current user group
        if (stage_user_group?.[recordDetail?.workflow_id]) {
          return userGroups.some(g =>
            (stage_user_group?.[recordDetail?.workflow_id] || []).includes(g)
          )
        }

        // If the rule contains a permissions value, compare the value with
        // the user permissions
        if (permissions?.length > 0) {
          return userPermissions.findIndex(p => permissions.includes(p)) !== -1
        }

        // Extracting value can be done with either field name or json path
        const fieldValue = getFieldValue(jsonPath || field);
        switch (operator) {
          // Field value equals to
          case "=":
          default:
            return fieldValue === value;
          // Field value contains a specific value
          // The field value must be an array
          case "contains":
            return Array.isArray(fieldValue) && fieldValue.some(fv => fv === value)
          // Field value is not nullish
          case "notNull":
            return ![null, undefined].includes(fieldValue);
        }
      });
    }

    // If there are no visibility, make it visible
    return true;
  };

  return {
    isVisible,
  };
};

export default evaluateVisibility;

import React, { useEffect, useState } from "react";
import left from "../../../../assets/images/Icon/left_pagi.svg";
import leftHover from "../../../../assets/images/Icon/left_pagi_hover.svg";
import right from "../../../../assets/images/Icon/right_pagi.svg";
import rightHover from "../../../../assets/images/Icon/right_pagi_hover.svg";
import "./pagination.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export const Pagination = (props) => {
  const [left_hover, setLeft_hover] = useState(false);
  const [right_hover, setRight_hover] = useState(false);
  const [page_number, setPage_number] = useState(1);
  const [pages, setPages] = useState(1);
  const [pagesCount, setPageCount] = useState(props.pageCount);
  const [actviePage, setActivePage] = useState(
    props.activePages ? props.activePages : 1
  );

  const pageChange = (Identifier) => {
    if (Identifier === "Increase") {
      props.handlePageChange(actviePage + 1);
      setActivePage(actviePage + 1);
      if (actviePage - 7 === pages) {
        setPages(pages + 8);
      }
    } else {
      props.handlePageChange(actviePage - 1);
      setActivePage(actviePage - 1);
      if (actviePage === pages) {
        setPages(pages - 8);
      }
    }
  };
  useEffect(() => {
    setPageCount(props.pageCount);
    setActivePage(props.activePages);
  }, [props.pageCount]);
  return (
    <div className="pagination_main_box">
      <div
        className="pagination_item_box_unactive"
        onMouseEnter={() => setLeft_hover(true)}
        onMouseLeave={() => setLeft_hover(false)}
        onClick={() => pageChange("Decrease")}
        style={{
          pointerEvents: actviePage === 1 ? "none" : "",
          opacity: actviePage === 1 ? 0.5 : 1,
        }}
      >
        <img src={left_hover === false ? left : leftHover} />
      </div>
      {pages > 1 && (
        <div
          className="pagination_item_box_unactive"
          onClick={() => {
            setPages(pages - 8);
            setActivePage(pages - 1);
            props.handlePageChange(pages - 1);
          }}
        >
          ...
        </div>
      )}
      {[0, 1, 2, 3, 4, 5, 6, 7].map((data, id) => {
        if (pagesCount - pages >= id)
          return (
            <div
              className={
                actviePage === pages + data
                  ? "pagination_item_box_active"
                  : "pagination_item_box_unactive"
              }
              key={data}
              onClick={() => {
                setActivePage(pages + data);
                props.handlePageChange(pages + data);
              }}
            >
              {pages + data}
            </div>
          );
      })}
      {pages < pagesCount - 8 && (
        <div
          className="pagination_item_box_unactive"
          onClick={() => {
            setPages(pages + 8);
            setActivePage(pages + 8);
            props.handlePageChange(pages + 8);
          }}
        >
          ...
        </div>
      )}
      <div
        className="pagination_item_box_unactive"
        onMouseEnter={() => setRight_hover(true)}
        onMouseLeave={() => setRight_hover(false)}
        onClick={() => pageChange("Increase")}
        style={{
          pointerEvents: actviePage === pagesCount ? "none" : "",
          opacity: actviePage === pagesCount ? 0.5 : 1,
        }}
      >
        <img src={right_hover === false ? right : rightHover} />
      </div>
      {props.showRow && (
        <div className="pagination_item_row_count_main_box">
          <div className="pagination_item_row_count_title">Row:</div>
          <DropDownList
            placeholder="Select"
            data={[5, 10, 15, 25, 35, 45, 55, 65, 75, 85, 100]}
            className="pagination_item_row_count_drop_down"
            value={props.rowCount}
            onChange={(e) => props.handleRowChange(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};

export const constData = [
  {
    user_group: "User 1",
    package: "-",
    source_of_observation: "-",
    company_carrying: "-",
    description_of_observation: "-",
    date_of_observation: "2024-08-16 20:29:28.308161+08:00",
    time_of_observation: "2024-08-16 20:29:28.308161+08:00",
    type_of_work_activity: "-",
    work_zone: "-",
    location_of_observation: "-",
    significant_level_of_observation: "-",
    type_of_risk: "-",
    hazards: "-",
    observation_originator_name: "Sithuta",
  },
];

export const ConfigData = [
  {
    id: "ref_no",
    name: "Id",
    isShow: true,
    width: "150px",
    filter: true,
    order_column: true,
    sorting_column: true,
  },
  {
    id: "user_group",
    name: "User Group",
    isShow: true,
    width: "117px",
    filter: true,
    order_column: true,
    sorting_column: true,
  },
  {
    id: "observation_originator_name",
    isShow: true,
    name: "Observation Originator Name",
    width: "155px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "package",
    isShow: true,
    name: "Package",
    width: "104px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "source_of_observation",
    isShow: true,
    name: "Source of Observation",
    width: "114px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "company_carrying",
    isShow: true,
    name: "Company Carrying Out Work Activity",
    width: "164px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "description_of_observation",
    isShow: true,
    name: "Description of Observation",
    width: "164px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "date_of_observation",
    isShow: true,
    name: "Date of Observation",
    width: "132px",
    format: "date",
    filter: true,
    sorting_column: false,
    order_column: true,
  },
  {
    id: "time_of_observation",
    isShow: true,
    name: "Time of Observation",
    width: "132px",
    format: "time",
    filter: true,
    sorting_column: false,
    order_column: true,
  },
  {
    id: "type_of_work_activity",
    isShow: true,
    name: "Type of Work Activity",
    width: "132px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "work_zone",
    isShow: true,
    name: "Work Zone",
    width: "132px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "location_of_observation",
    isShow: true,
    name: "Location of Observation",
    width: "132px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "significant_level_of_observation",
    isShow: true,
    name: "Significant Level of Observation",
    width: "167px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "type_of_risk",
    isShow: true,
    name: "Type of Risk",
    width: "127px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "hazards",
    isShow: true,
    name: "Hazards",
    width: "99px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "status",
    isShow: true,
    name: "Status",
    width: "99px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "potential_consequences",
    isShow: true,
    name: "Potential Consequences",
    width: "167px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "access_identifier_worker",
    isShow: true,
    name: "acCEss Identifier (Worker 1)",
    width: "167px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "access_identifier_worker1",
    isShow: true,
    name: "acCEss Identifier (Worker 2)",
    width: "167px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "access_identifier_supervisor",
    isShow: true,
    name: "acCEss Identifier (Supervisor)",
    width: "167px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "access_identifier_equipment",
    isShow: true,
    name: "acCEss Identifier (Equipment 1)",
    width: "167px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "access_identifier_equipment1",
    isShow: true,
    name: "acCEss Identifier (Equipment 2)",
    width: "167px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "detailed_work_activity",
    isShow: true,
    name: "Detailed Description of Work Activity",
    width: "190px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "other_work_activity",
    isShow: true,
    name: "Others (Type of Work Activity)",
    width: "190px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "other_hazards",
    isShow: true,
    name: "Others (Hazards)",
    width: "99px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "other_potential_consequences",
    isShow: true,
    name: "Others (Potential Consequences)",
    width: "190px",
    filter: true,
    sorting_column: true,
    order_column: true,
  },
  {
    id: "checklist.name",
    isShow: true,
    name: "Checklist",
    width: "99px",
    filter: true,
    sorting_column: true,
    order_column: true,

    // objectValue: "name",
  },
  {
    id: "checklist.other_details",
    isShow: true,
    name: "Any other details to be captured?",
    width: "200px",
    filter: true,
    sorting_column: true,
    order_column: true,
    // objectValue: "other_details",
  },
  {
    id: "checklist.hira",
    isShow: true,
    name: "HIRA Applicable",
    width: "200px",
    filter: true,
    sorting_column: true,
    order_column: false,
    // objectValue: "hira",
  },
];

import { useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  TextBox,
} from "@progress/kendo-react-inputs";
import { TextFieldSettings } from './TextFieldSettings';
import { Text } from '../Text';
import { FormContext } from '../../../context/form';
import evaluateVisibility from '../../utils/evaluateVisibility';
import FieldLabel from '../../../FieldLabel';

const StyledTextField = styled.div`
  margin: ${({ margin = [] }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
`;

export const TextField = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  // Consume form values from the provider.
  // It makes selector components tightly coupled with the parent,
  // but this is by far the most efficient way of doing it.
  const { state, recordState } = useContext(FormContext)
  const [values, setValues] = state
  const [recordDetail] = recordState

  const {
    margin,
    textComponent,
    id,
    label: text,
    visibility = [],
    disabled,
    tabID,
    required,
    ...otherProps
  } = props;

  const { isVisible } = evaluateVisibility({ values, visibility, tabID, recordDetail })
  const handleOnChange = (e) => {
    if (values.ohs) {
      if (props.tabID >= 1) {
        if (values.ohs.length < props.tabID) {
          setValues({
            ...values,
            ohs: [...values.ohs, { [id]: e.target.value }]
          })
        } else {
          if (values.ohs[props.tabID - 1]?.[id]) {
            let update = values.ohs[props.tabID - 1]
            update[id] = e.target.value
            setValues({
              ...values
            })
          } else {
            values.ohs[props.tabID - 1][id] = e.target.value
            setValues({
              ...values
            })
          }
        }
      }
    } else {
      if (props.tabID > 0) {
        setValues({
          ...values,
          ohs: [{
            [id]: e.target.value
          }]
        })
      } else {

        setValues({
          ...values,
          [id]: e.target.value,
        })
      }
      // }
    }
  }

  useEffect(() => {
    // Evaluate required field if it is visible
    if (required && isVisible()) {
      // TODO: Refactor this complicated ternary
      const cases =
        values.ohs === undefined
          ? true
          : values.ohs[props.tabID - 1] &&
            values.ohs[props.tabID - 1][id] !== undefined
            ? values.ohs[props.tabID - 1][id] === ""
              ? true
              : false
            : true;
      if (cases === true) {
        props.checkValidation(true, `${id} ${props.tabID}`)
      } else {
        props.checkValidation(false, `${id} ${props.tabID}`)
      }
    }
  }, [required, isVisible(), values])

  return isVisible()
    ? (
      <StyledTextField
        ref={connect}
        className={cx([
          'w-full detail-item-box field-validation__wrapper',
        ])}
        margin={margin}
      >
        <FieldLabel {...textComponent} text={text} required={required} />
        <TextBox
          {...otherProps}
          className={cx([
            'w-full work-textbox',
          ])}
          onChange={handleOnChange}
          value={props.tabID >= 1 && values.ohs && values.ohs[props.tabID - 1]?.[id]}
          disabled={disabled}
        />
        {required && props.tabID >= 1 && (
          <>
            {values.ohs === undefined ? (<div className='OHS_FORM_validation'>
              This field is required!</div>) : values.ohs && (values.ohs[props.tabID - 1]?.[id] === undefined || values.ohs[props.tabID - 1]?.[id] === "") && (<div className='OHS_FORM_validation'>
                This field is required!</div>)}
          </>
        )}
      </StyledTextField>
    )
    : null;
};

TextField.craft = {
  displayName: 'Text Field',
  props: {
    margin: ['5', '0', '5', '0'],
    placeholder: 'Enter a value...',
    id: '',
    text: 'Field label',
    textComponent: {
      ...Text.craft.props,
    },
  },
  related: {
    toolbar: TextFieldSettings,
  },
};

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import MobileHeaderFiltered from "../../../components/mobileHeaderFiltered/mobileHeaderFiltered";
import { Button } from "@progress/kendo-react-buttons";
import FormDesigner from "./form_designer";
import FormProvider, { FormContext } from "./context/form";
import ConfirmMsg from "../../../components/confirmMsg";
import LeftArrowIcon from "../../../assets/images/incident/left-arrow.svg";
import RightArrowIcon from "../../../assets/images/incident/right-arrow.svg";
import CrossIcon from "../../../assets/images/Icon/Cross_black.svg";
import {
  OHS_INSPECTION_DETAIL_ROUTE,
  OHS_INSPECTION_EDIT_ROUTE,
  OHSINSPECTION_ROUTE,
} from "../../../routes";
import api from "../../../config/api";
import config from "../../../config/config";
import "../ohs.css";
import LoadingIndicator from "./LoadingIndicator";
import {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
} from "../../../config/userPool";
import { useDispatch, useSelector } from "react-redux";
import { fetchALLAUDIT } from "../../../store/AuditTrailSlice";
import { Dialog } from "@progress/kendo-react-dialogs";
import { AuditTrail } from "../../incident/details/AduitTrail";

const RandomizeId1 = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  ",",
  ".",
  ":",
  ";",
];

const FormAction = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const {
    loadingState,
    isDetail,
    isEdit,
    isClosed,
    setShowAudit,
    recordId,
    recordDetail,
  } = props;
  const { OHS_CREATE_ENDPOINT, PUT_OHS_ENDPOINT, OHS_APPROVAL_ENDPOINT } =
    config.api_endpoint;
  const {
    APPROVAL_OHS,
    APPROVAL_OHS_LIMITED,
    CREATE_OHS,
    CREATE_OHS_LIMITED,
    EDIT_OHS,
    EDIT_OHS_LIMITED,
    RECTIFICATION_OHS,
    RECTIFICATION_OHS_LIMITED,
    VETTER_OHS,
    VETTER_OHS_LIMITED,
  } = config.permissions;

  const {
    token,
    state,
    userData,
    userPermissions,
    userGroups,
    configState,
    touchedState,
    errorsState,
  } = useContext(FormContext);
  const [formConfig] = configState;
  const { workflow = [], widgets = [] } = formConfig || {};
  const [, setLoading] = loadingState;
  const navigate = useNavigate();
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [values] = state;
  const [errors] = errorsState;
  const [, setTouched] = touchedState;

  // Function to find the "panel" object and extract stage_user_group
  const findStageUserGroup = (widgetId) => {
    // Search for the panel with the widget id in the widgets array
    const panel = widgets.find((widget) => widget.id === widgetId);

    if (panel) {
      // If the panel object is found, search for stage_user_group
      for (let rule of panel?.visibility?.rules) {
        if (rule.stage_user_group) {
          return rule.stage_user_group;
        }
      }
    }

    return null; // Return null if no stage_user_group is found
  };

  // Get workflow from the form configuration by the workflow id
  const getWorkflowById = (id) => workflow.find((w) => w.id === id);

  // This is where you resolve status and approval_status
  // From the workflow_id value
  const resolveWorkflowValue = (payload) => {
    const { workflow_id } = payload?.ohs?.[0] || payload || {};
    const currentStage = getWorkflowById(workflow_id);
    const status = currentStage?.name;
    const workflowId = currentStage?.id;
    return {
      ...payload,
      status,
      workflow_id: workflowId,
    };
  };

  // Check if user can approve
  const canUserApprove = useMemo(() => {
    const approvalVisibility = findStageUserGroup("panel-approval");
    return userGroups.some((g) =>
      (approvalVisibility?.[recordDetail?.workflow_id] || []).includes(g)
    );
  }, [userGroups, recordDetail]);

  // Check if user can vet
  const canUserVet = useMemo(() => {
    const vetterVisibility = findStageUserGroup("panel-vetter");
    return userGroups.some((g) =>
      (vetterVisibility?.[recordDetail?.workflow_id] || []).includes(g)
    );
  }, [userGroups, recordDetail]);

  // Check whether the current record is created by current user
  const isUserOriginator = useMemo(() => {
    return recordDetail?.created_by === userData?.data?.email;
  }, [userData, recordDetail]);

  // Check whether the current user is a creator
  const isUserCreator = useMemo(() => {
    return [CREATE_OHS, CREATE_OHS_LIMITED].some((r) =>
      userPermissions.includes(r)
    );
  }, [userPermissions]);

  // Check whether the current user is an editor
  const isUserEditor = useMemo(() => {
    return [EDIT_OHS, EDIT_OHS_LIMITED].some((r) =>
      userPermissions.includes(r)
    );
  }, [userPermissions]);

  // Check whether the current user is a rectifier
  const isUserRectifier = useMemo(() => {
    return [RECTIFICATION_OHS, RECTIFICATION_OHS_LIMITED].some((r) =>
      userPermissions.includes(r)
    );
  }, [userPermissions]);

  // Get record status from the record detail (and not the values state)
  // so that when user update status in real time
  // (from Status dropdown) it will not change the state of the buttons.
  const recordStatus =
    recordDetail?.status !== undefined
      ? recordDetail?.status
      : recordDetail?.ohs?.[0]?.status;

  // Reusable observation handler factory.
  // Usage: create a function from the factory, it handles the response from the API, e.g.
  // const saveDraft = createObservationHandler(someFn), then call the function, e.g.:
  // saveDraft({ workflow_id: 'draftworkflowid' }). Note that record status can be set either by these buttons
  // or via the form's status dropdown field
  const createObservationHandler =
    (handleResponse) =>
    async (payload = {}, apiPath) => {
      // Resolve workflow value from the form values
      let approvalValues = resolveWorkflowValue(values);

      // set approval values from the payload arg if provided (override)
      if (Object.keys(payload).length > 0) {
        approvalValues = resolveWorkflowValue(payload);
      }

      try {
        setLoading(true);

        // Custom format for checklist
        if (values?.checklist) {
          values.checklist = [
            {
              key: values.checklist,
              value: "Yes",
            },
          ];
        }

        let observationAPI = [];
        const randomChar = Math.floor(Math.random() * RandomizeId1.length);
        const randomChar1 = Math.floor(Math.random() * RandomizeId1.length);
        const randomChar2 = Math.floor(Math.random() * RandomizeId1.length);

        const { status, workflow_id, ...approvalPayload } = approvalValues;
        const records = values.ohs || [1];

        records.map((record) => {
          const { other_details, ...rest } = record;
          // Create a shallow copy so that it's writable
          const data = values.wdc_id
            ? { ...rest, wdc_id: values.wdc_id, wdc_ref_no: values.wdc_ref_no }
            : { ...rest };

          // Move other details inside of checklist
          if (data.checklist && other_details) {
            data.checklist = {
              ...data.checklist,
              other_details,
            };
          }

          if (recordId) {
            observationAPI = {
              // Append approval payload
              ...(apiPath === OHS_APPROVAL_ENDPOINT ? approvalPayload : {}),
              ...data,
              status,
              workflow_id,
              observation_id: `${
                Math.floor(Math.random() * (1000000000000 - 1 + 1)) + 1
              }${RandomizeId1[randomChar]}${RandomizeId1[randomChar1]}${
                RandomizeId1[randomChar2]
              }`,
              user_group: values.user_group,
              package: values.package,
              observation_originator_name: values.observation_originator_name,
              significant_level_of_observation:
                values.significant_level_of_observation,
              updated_by: userData.data?.email,
            };
          } else {
            observationAPI.push({
              ...data,
              status,
              workflow_id,
              observation_id: `${
                Math.floor(Math.random() * (1000000000000 - 1 + 1)) + 1
              }${RandomizeId1[randomChar]}${RandomizeId1[randomChar1]}${
                RandomizeId1[randomChar2]
              }`,
              user_group: values.user_group,
              package: values.package,
              observation_originator_name: values.observation_originator_name,
              significant_level_of_observation:
                values.significant_level_of_observation,
              created_by: userData.data?.email,
            });
          }
        });

        let apiUrl;
        let apiMethod;

        if (recordId) {
          apiUrl = `${PUT_OHS_ENDPOINT}/${recordId}`;
          apiMethod = "put";
        } else {
          apiUrl = OHS_CREATE_ENDPOINT;
          apiMethod = "post";
        }

        // Override API URL if API path is provided
        // Currently used in the for_approval stage
        if (apiPath) {
          apiUrl = apiPath;
          apiMethod = "post";
        }

        let UploadData = recordId
          ? { ...observationAPI }
          : {
              observations: observationAPI,
            };
        const response = await api[apiMethod](
          apiUrl,
          JSON.stringify(UploadData),
          token
        );
        handleResponse(response);
      } catch (e) {
        console.debug(`Failed adding/updating record`, e);
      } finally {
        setLoading(false);
      }
    };

  const saveDraft = createObservationHandler((response) => {
    if (response?.details === "success") {
      if (response?.data?.[0]?.id) {
        navigate(
          `${OHS_INSPECTION_DETAIL_ROUTE}?id=${response?.data?.[0]?.id}`
        );
      } else {
        navigate(`${OHS_INSPECTION_DETAIL_ROUTE}?id=${recordId}`);
      }
    } else {
      // There are various responses from the API
      // First variant: details or data property
      if (!!response.details || !!response.data) {
        alert(`${response.details} ${JSON.stringify(response.data)}`);
        // Second variant: Message property
      } else if (!!response.Message) {
        alert(`${response.Message}`);
      }
    }
  });

  const submitRecord = createObservationHandler((response) => {
    if (response?.details === "success") {
      navigate(`${OHSINSPECTION_ROUTE}`);
    }
  });

  const approveOrRejectRecord = () => {
    const currentValues = { ...(values?.ohs?.[0] || values) };
    const {
      id,
      workflow_id,
      // Mark approval status approved by default
      // e.g. in case of the record is in for_rectification status
      approval_status = "approved",
      corrective_action_taken,
      preventive_action_taken,
      rectification_picture,
      rectification_supporting_document,
      remarks_for_rejection,
      evidence_of_rejection,
    } = currentValues;

    // Destructure main details
    const { user_group } = values || {};

    let currentStage = workflow.find((w) => w.id === workflow_id);
    let nextStage = null;

    // If the current workflow (stage) has output, go to the output
    if (currentStage?.output) {
      nextStage = currentStage?.output;

      // If the current workflow (stage) is for_approval, go to one of the path
    } else if (currentStage?.name === "for_approval") {
      nextStage = currentStage?.path?.[approval_status];

      // If the current workflow (stage) does not have path, it is the final stage
      // e.g.: closed, not_valid
    } else {
      nextStage = currentStage?.id;
    }

    // If the next stage is an if else statement, evaluate first
    const nextWorkflow = getWorkflowById(nextStage);
    if (nextWorkflow?.name === "if_else") {
      let evaluation = true;
      switch (nextWorkflow?.condition) {
        case "is_safety_oversight_record":
          evaluation = user_group === "Safety Oversight Team";
          break;
        default:
          break;
      }

      // if truthy, go to the 'true' path, otherwise go to 'false' path
      if (evaluation) {
        nextStage = nextWorkflow?.path?.true;
      } else {
        nextStage = nextWorkflow?.path?.false;
      }
    }

    // Populate workflow status from the stage evaluation above
    const { status, workflow_id: workflowId } = resolveWorkflowValue({
      workflow_id: nextStage,
    });

    // Populate rectification payload from the values
    const rectification = {
      corrective_action_taken,
      preventive_action_taken,
      rectification_picture,
      rectification_supporting_document,
    };

    // TODO: Differentiate between project and SO team payload
    // Populate rejection payload from the values
    const project_team_approval = {
      remarks_for_rejection,
      evidence_of_rejection,
    };

    // Populate rejection payload from the values
    const safety_oversight_team_approval = {
      remarks_for_rejection,
      evidence_of_rejection,
    };

    let teamApproval = {};
    if (user_group === "Project Team") {
      teamApproval = { project_team_approval };
    } else if (user_group === "Safety Oversight Team") {
      teamApproval = { safety_oversight_team_approval };
    }

    const actionFn = createObservationHandler((response) => {
      if (response?.details === "success") {
        navigate(`${OHSINSPECTION_ROUTE}`);
      }
    });

    const approvalData = {
      inspection_id: id,
      approval_status,
      rectification,
      rectification_by: userData?.data?.email,
      approved_by: userData?.data?.email,
      status,
      workflow_id: workflowId,
      ...teamApproval,
    };

    // If approved, call the approval API with the approval data as its payload
    return actionFn(approvalData, OHS_APPROVAL_ENDPOINT);
  };

  const hasErrors = useMemo(() => {
    // Filter the errors array to find unresolved errors
    return errors.some((err) => {
      // Destructure the error object to separate id, tabID, and the rest of the properties
      const { id, tabID, ...errorRules } = err;

      // Check if any of the error rules are true (indicating unresolved errors)
      return Object.values(errorRules).some((rule) => rule === true);
    });
  }, [errors]);

  const checkSubmitValidation = () => {
    let statusCheck = false;
    if (props.ohsForm.length === props.status.length) {
      props.status.map((i, id) => {
        if (
          i.main_details !== undefined &&
          i.main_details.find((j) => j.value === true)
        ) {
          statusCheck = true;
        }
        if (
          i?.[`OHS ${id}`] !== undefined &&
          i?.[`OHS ${id}`].find((j) => j.value === true)
        ) {
          statusCheck = true;
        }
      });
    } else {
      statusCheck = true;
    }
    return statusCheck;
  };

  const shouldShowButton = () => {
    switch (recordStatus) {
      // Non editor/creator role cannot edit drafts or new record
      case undefined:
      case "draft":
        return isUserEditor || isUserCreator;

      // Original creator cannot amend open records
      // Approver cannot amend open open records
      // (unless they also have create and edit permissions, e.g. admin)
      case "open":
        return !isUserOriginator && canUserVet;

      // Non rectifier cannot amend for_recitifer records
      case "for_rectification":
        return isUserRectifier;

      // Non approver cannot amend for_approval records
      case "for_approval":
        return canUserApprove;

      // Closed records should always hide button
      case "closed":
        return true;

      default:
        return false;
    }
  };

  const RenderActions = () => {
    const DraftBtn = ({ label = "Save as Draft" }) => {
      return (
        <Button
          className="Work_footer_button"
          onClick={() =>
            saveDraft({
              workflow_id: "draft-ujImcBULcfUeHjp2Gni4wA",
            })
          }
        >
          {label}
        </Button>
      );
    };
    const SubmitBtn = () => (
      <Button
        className="Work_footer_button submit-button"
        onClick={() => {
          setTouched(true);
          if (checkSubmitValidation() || hasErrors) {
            try {
              // Scroll to the first invalid field
              const firstInvalidElement = document
                .querySelector(".OHS_FORM_validation")
                ?.closest(".field-validation__wrapper");
              if (firstInvalidElement) {
                firstInvalidElement.scrollIntoView();
              }
            } catch {}
            return;
          }
          setShowConfirmPopUp(true);
        }}
      >
        Submit
      </Button>
    );

    if (!shouldShowButton()) {
      return null;
    }

    if (isDetail) {
      return (
        <>
          <Button
            className="Work_footer_button submit-button"
            onClick={() =>
              navigate(`${OHS_INSPECTION_EDIT_ROUTE}?id=${recordId}`)
            }
          >
            Edit
          </Button>

          {/** Fix mobile appearance to prevent Edit button from stretching all the way horizontally */}
          {/* <span className="flex-1" /> */}
        </>
      );
    }

    if (isEdit) {
      switch (recordStatus) {
        case "draft":
          return (
            <>
              <DraftBtn label={"Save"} />
              <SubmitBtn />
            </>
          );
        case "open":
        case "for_approval":
        case "for_rectification":
          return <SubmitBtn />;
        case "closed":
        default:
          return null;
      }
    }

    return (
      <>
        <DraftBtn />
        <SubmitBtn />
      </>
    );
  };

  return (
    <>
      {isMobile ? (
        <div className="WorkDE_footer_main_box w-[calc(100%_-_100px)] ohs__actions custom_footer">
          <div className="footer-left" style={{ gap: "10px" }}>
            <Button
              className="Work_footer_button"
              onClick={() => navigate(OHSINSPECTION_ROUTE)}
            >
              Cancel
            </Button>
            {isDetail && (
              <Button
                className="Work_footer_button"
                onClick={() => setShowAudit(true)}
              >
                View Audit Trail
              </Button>
            )}
            {userData?.data === null ? (
              <LoadingIndicator style={{ height: "40px" }} />
            ) : (
              <RenderActions
                isDetail={isDetail}
                isEdit={isEdit}
                isClosed={isClosed}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="WorkDE_footer_main_box w-[calc(100%_-_100px)] ohs__actions custom_footer">
          <div className="footer-left" style={{ gap: "10px" }}>
            <Button
              className="Work_footer_button"
              onClick={() => navigate(OHSINSPECTION_ROUTE)}
            >
              Cancel
            </Button>
            {isDetail && (
              <Button
                className="Work_footer_button"
                onClick={() => setShowAudit(true)}
              >
                View Audit Trail
              </Button>
            )}
          </div>
          <div className="footer-right">
            {userData?.data === null ? (
              <LoadingIndicator style={{ height: "40px" }} />
            ) : (
              <RenderActions
                isDetail={isDetail}
                isEdit={isEdit}
                isClosed={isClosed}
              />
            )}
          </div>
        </div>
      )}
      {showConfirmPopUp && (
        <ConfirmMsg
          onCloseDialog={() => {
            setShowConfirmPopUp(false);
          }}
          msgTitle={"You are submitting an OHS inspection."}
          okBtnMsg={"Yes"}
          msgContent={`By submitting, you acknowledged all submission and documents are in
            accordance with requirements. Please confirm.`}
          okBtnClicked={() => {
            if (!recordStatus || ["draft", "open"].includes(recordStatus)) {
              const openStatuses = {
                "Project Team": "open-wkVqYY2oRRKIyy_ef6TlxA",
                "Safety Oversight Team": "open-v0xuEJfUQyKBXrlbh2_t6Q",
              };
              submitRecord(
                ["open"].includes(recordStatus)
                  ? // pass undefined so that it reads from the For Vetter status dropdown
                    undefined
                  : { workflow_id: openStatuses[values?.user_group] }
              );
            } else {
              approveOrRejectRecord();
            }
          }}
          cancelBtnMsg={"No"}
          cancelBtnClicked={() => {
            setShowConfirmPopUp(false);
          }}
        />
      )}
    </>
  );
};

// Separating the form component so that it has access to the form context
const InspectionForm = (props) => {
  const dispatch = useDispatch();
  const audit = useSelector((state) => state.audit);
  const { CREATE_OHS, CREATE_OHS_LIMITED, EDIT_OHS, EDIT_OHS_LIMITED } =
    config.permissions;
  const { GET_MAP_LAYER_ENDPOINT } = config.api_endpoint;
  const { isDetail, isEdit } = props;
  const [showDraftButton, setShowDraftButton] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const statusBarRef = useRef(null);
  const { state, recordState, configState } = useContext(FormContext);
  const [formConfig] = configState;
  const [mapLayerImage, setMapLayerImage] = useState("");
  const [showAudit, setShowAudit] = useState(false);

  const [values, setValues] = state;
  const [recordDetail, setRecordDetail] = recordState;

  const [params] = useSearchParams();
  const recordId = params.get("id");
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("Main Details");
  const [tabLoading, setTabLoading] = useState(false);
  let [status, setStatus] = useState([]);
  const [observationStatus, setObservationStatus] = useState(false);
  const [removeObservation, setRemoveObservation] = useState(false);
  const [deleteFormID, setDeleteFormID] = useState(null);
  const [tempID, setTempID] = useState(null);
  let [ohsForm, setOhsForm] = useState([
    { id: "Main Details" },
    { id: "OHS", tabID: 1 },
  ]);

  const shouldLoadDetail = useMemo(
    () => isDetail || isEdit,
    [isDetail, isEdit]
  );

  function removeNullUndefined(obj) {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    }
  }

  const shouldShowSaveDraft = (user) => {
    if (user.sub) {
      if (
        user.permissions.filter((x) =>
          [CREATE_OHS, CREATE_OHS_LIMITED].includes(x)
        ).length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const shouldShowSubmit = (user, ohs) => {
    if (user.sub && ohs) {
      if (!ohs.id) {
        if (
          user.permissions.filter((x) =>
            [CREATE_OHS, CREATE_OHS_LIMITED].includes(x)
          ).length > 0
        ) {
          return true;
        }
      } else {
        if (user.permissions.indexOf(EDIT_OHS) > -1) {
          return true;
        } else if (
          user.permissions.indexOf(EDIT_OHS_LIMITED) > -1 &&
          user.package.indexOf(ohs.package) > -1
        ) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    let detailLoaded = false;
    let current_user = JSON.parse(localStorage.getItem("current_user"));
    if (shouldShowSaveDraft(current_user)) {
      setShowDraftButton(true);
    }

    const loadDetail = async () => {
      try {
        setLoading(true);
        const token = await getCurrentLoggedInSessionTokenWithPromise();
        const response = await api.get(
          `/api-ohs/inspection/${recordId}`,
          {},
          token
        );
        if (response?.data?.id) {
          setRecordDetail(response.data);
          let data = { ...response.data };
          let valueEdit = {
            user_group: data.user_group,
            observation_originator_name: data.observation_originator_name,
            package: data.package,
            significant_level_of_observation:
              data.significant_level_of_observation,
          };
          if (data.wdc_id || data.wdc_ref_no) {
            valueEdit = {
              ...valueEdit,
              wdc_id: data.wdc_id,
              wdc_ref_no: data.wdc_ref_no,
            };
          }
          delete data.user_group;
          delete data.observation_originator_name;
          delete data.package;
          delete data.significant_level_of_observation;
          removeNullUndefined(data);

          // Temporary workaround when a workflow_id does not match the status,
          // e.g. status = closed but workflow_id = open-xxx
          // TODO: Find out where the mismatch came from.
          if (
            data?.status === "closed" &&
            !(data?.workflow_id || "").match(/^closed/)
          ) {
            const { workflow = [] } = formConfig || {};
            const properStatus = ["Compliance", "No Activity"].includes(
              valueEdit?.significant_level_of_observation
            )
              ? "closed"
              : "not_valid";
            const currentWorkflow = workflow.find(
              (wf) => wf.name === properStatus
            );
            if (currentWorkflow) {
              data.workflow_id = currentWorkflow.id;
            }
          }

          valueEdit = {
            ...valueEdit,
            ohs: [
              {
                ...data,
                other_details: data.checklist?.other_details,
              },
            ],
          };
          setValues(valueEdit);
        }
        if (shouldShowSubmit(current_user, response.data)) {
          setShowSubmitButton(true);
        } else {
          setShowSubmitButton(false);
        }
      } catch {
        console.debug("Failed loading inspection detail");
      } finally {
        setLoading(false);
      }
    };

    if (
      !detailLoaded &&
      Object.keys(values)?.length === 0 &&
      // Load detail only when the form configuration has been loaded
      formConfig !== null &&
      shouldLoadDetail
    ) {
      dispatch(fetchALLAUDIT(recordId));
      loadDetail();
    } else {
      if (shouldShowSaveDraft(current_user)) {
        setShowSubmitButton(true);
      }
    }

    // Clean up effect
    return () => {
      detailLoaded = true;
    };
  }, [recordId, values, setValues, shouldLoadDetail, formConfig]);

  useEffect(() => {
    // Reset values to trigger refetch
    setValues({});
  }, [recordId]);

  useEffect(() => {
    setLoading(true);
    async function getData() {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const currentUser = await getCurrentLoggedInUserWithPromise();
      let current_user = JSON.parse(localStorage.getItem("current_user"));
      const link_wdc = params.get("wdc_id");
      if (link_wdc !== null) {
        console.log("values", values);
        setValues({
          ...values,
          wdc_id: link_wdc.split("?")[0],
          wdc_ref_no: link_wdc.split("?")[1].split("=")[1],
        });
      }
      api
        .post(
          GET_MAP_LAYER_ENDPOINT,
          JSON.stringify({ filter_require_in_ohs: 1 }),
          token
        )
        .then((result) => {
          if (result.status_code === 200) {
            setMapLayerImage(result.items);
            setLoading(false);
          } else {
            setLoading(false);
            alert(result.error);
          }
        });
    }
    getData();
  }, []);

  const pageTitle = useMemo(() => {
    if (isDetail) {
      return "View OHS Inspection";
    }

    if (isEdit) {
      return "Edit OHS Inspection";
    }

    return "Add OHS Inspection";
  }, [isDetail, isEdit]);

  const leftArrowClicked = () => {
    statusBarRef.current.scrollBy({ top: 0, left: -100, behavior: "smooth" });
  };

  const rightArrowClicked = () => {
    statusBarRef.current.scrollBy({ top: 0, left: 100, behavior: "smooth" });
  };

  const tabChange = (i, id) => {
    setTabLoading(true);
    setTab(i === "Main Details" ? i : `${i} ${id}`);
    setTimeout(() => {
      setTabLoading(false);
    }, 200);
  };

  const addForm = (id) => {
    console.log(id);
    console.log(ohsForm.find((i, index) => console.log(index)));
    if (ohsForm.length <= 5) {
      if (!ohsForm.find((i, index) => index === id + 1)) {
        // ohsForm.push({
        //   id: `OHS`,
        //   tabID: id + 1,
        // });
        setOhsForm([
          ...ohsForm,
          {
            id: `OHS`,
            tabID: id + 1,
          },
        ]);
        setTab(`OHS ${id + 1}`);
      }
    }
  };

  const deleteForm = (id) => {
    setRemoveObservation(false);
    console.log(id);
    // const newArray = ohsForm.filter((item, index) => index !== id + 1);
    const newArray = ohsForm.filter((item, index) => index !== id);
    const newArrayForData =
      values.ohs && values.ohs.filter((item, index) => index + 1 !== id);
    const newArrayForValidation =
      status && status.filter((item, index) => index !== id);
    console.log();
    if (newArrayForData[newArrayForData.length - 1].other_observation) {
      newArrayForData[newArrayForData.length - 1].other_observation = "No";
    } else {
      newArrayForData[newArrayForData.length - 1] = {
        ...newArrayForData[newArrayForData.length - 1],
        other_observation: "No",
      };
    }
    values.ohs = newArrayForData;
    setValues({
      ...values,
    });
    setTab(`${newArray[newArray.length - 1].id} ${id - 1}`);
    ohsForm = newArray;
    status = newArrayForValidation;
    setStatus(status);
    setOhsForm(ohsForm);
  };

  const DeleteOHS = () => {
    setTabLoading(true);
    const copyOHSForm = ohsForm.slice(0, 2);
    const copyStatus = status.slice(0, 2);
    status = copyStatus;
    setStatus([...copyStatus]);
    ohsForm = copyOHSForm;
    setOhsForm([...copyOHSForm]);
    const copyValue = values.ohs && values.ohs.slice(0, 1);
    setTab(`OHS 1`);
    setValues({
      ...values,
      ohs: [...copyValue],
    });
    setTimeout(() => {
      setTabLoading(false);
    }, 150);
    setObservationStatus(false);
  };

  const removeFormTemp = (stats, id) => {
    if (values.ohs && values.ohs.length > 0) {
      setTempID(id);

      setObservationStatus(true);
    }
  };

  const cancelOHSRemove = () => {
    const update = values.ohs[tempID - 1];
    update["other_observation"] = "Yes";
    setValues({
      ...values,
    });
    setObservationStatus(false);
  };

  const isClosed = useMemo(
    () => values?.status === "closed" || values?.[0]?.status === "closed",
    [values]
  );

  const isDraft = useMemo(
    () => recordDetail?.status === "draft",
    [recordDetail]
  );

  console.log("audit", audit);

  return (
    <div className="OHS-inspection-form">
      {removeObservation && (
        <ConfirmMsg
          onCloseDialog={() => {
            setRemoveObservation(false);
          }}
          msgTitle={`You are deleting OHS${deleteFormID}!`}
          okBtnMsg={"Yes"}
          msgContent={`This action cannot be undone and the filled data for OHS${deleteFormID} will be lost`}
          // okBtnClicked={DeleteOHS}
          okBtnClicked={() => deleteForm(deleteFormID)}
          cancelBtnMsg={"No"}
          // cancelBtnClicked={() => {
          //   cancelOHSRemove();
          // }}
          cancelBtnClicked={() => {
            // cancelOHSRemove();
            setRemoveObservation(false);
          }}
        />
      )}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="Work_create">
          {isMobile ? (
            <MobileHeaderFiltered
              title={pageTitle}
              route={OHSINSPECTION_ROUTE}
              icons={[]}
            />
          ) : (
            <MobileHeaderFiltered
              title={pageTitle}
              route={OHSINSPECTION_ROUTE}
              icons={[]}
            />
          )}
          {showAudit && (
            <Dialog className="Audit">
              <div style={{ position: "sticky", top: 0, zIndex: 3 }}>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "22px",
                      right: "0px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowAudit(false)}
                  >
                    <img src={CrossIcon} />
                  </div>
                </div>
              </div>
              <div className="Audit_trail_title">Audit trail</div>
              <div className="Audit_trail_text_box" style={{ height: "450px" }}>
                <AuditTrail data={audit && audit} />
              </div>
            </Dialog>
          )}
          <div className="OHS_FORM_TAB_ICON_main_box">
            {!shouldLoadDetail || isDraft ? (
              <div
                className="OHS_FORM_TAB_Icon hidden"
                style={{ order: 1 }}
                onClick={leftArrowClicked}
              >
                <img src={LeftArrowIcon} />
              </div>
            ) : null}
            <div
              className="OHS_FORM_TAB_main_box"
              style={{ order: 2 }}
              ref={statusBarRef}
            >
              {ohsForm.map((i, id) => (
                <div
                  key={id}
                  className="OHS_FORM_TAB_child"
                  style={{
                    borderBottom:
                      `${i.id === "Main Details" ? i.id : `${i.id} ${id}`}` ===
                        tab && "6px solid",
                  }}
                >
                  <span style={{ flex: 1 }} onClick={() => tabChange(i.id, id)}>
                    {i.id} {id === 0 ? "" : id}
                  </span>
                  {id > 1 && (
                    <span
                      style={{ width: "24px", height: "24px" }}
                      onClick={() => {
                        // deleteForm(id);
                        setDeleteFormID(id);
                        setRemoveObservation(true);
                      }}
                    >
                      <img src={CrossIcon} />
                    </span>
                  )}
                </div>
              ))}
              {/* <div className="OHS_FROM_TAB_new">+</div> */}
            </div>
            {!shouldLoadDetail || isDraft ? (
              <div
                className="OHS_FORM_TAB_Icon hidden"
                style={{ order: 3 }}
                onClick={rightArrowClicked}
              >
                <img src={RightArrowIcon} />
              </div>
            ) : null}
          </div>
          <div className="OHS_Create_Detail_box">
            {tabLoading
              ? null
              : ohsForm.map((i, id) => {
                  if (
                    (i.id === "Main Details" ? i.id : `${i.id} ${id}`) === tab
                  ) {
                    return (
                      <div key={id}>
                        <FormDesigner
                          disabled={isDetail}
                          key={i.id}
                          tab={i.id}
                          tabID={id}
                          addForm={addForm}
                          removeForm={removeFormTemp}
                          status={status}
                          setStatus={setStatus}
                          deleteForm={deleteForm}
                          mapLayerImage={mapLayerImage}
                        />
                      </div>
                    );
                  }
                })}
          </div>
          <FormAction
            loadingState={[loading, setLoading]}
            isDetail={isDetail}
            isEdit={isEdit}
            isClosed={isClosed}
            recordId={recordId}
            recordDetail={recordDetail}
            status={status}
            ohsForm={ohsForm}
            showDraftButton={showDraftButton}
            showSubmitButton={showSubmitButton}
            setShowAudit={setShowAudit}
          />
        </div>
      )}
    </div>
  );
};

const OhsInspectionForm = (props) => {
  return (
    <FormProvider>
      <InspectionForm {...props} />
    </FormProvider>
  );
};

export default OhsInspectionForm;

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MobileHeaderFiltered from "../../components/mobileHeaderFiltered/mobileHeaderFiltered";
import { MORE_ROUTE } from "../../routes";
import { Switch } from "@progress/kendo-react-inputs";
import config from "../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import { Loader } from "@progress/kendo-react-indicators";

export const NotificationSetting = () => {
  const { GET_USER_ALT_ENDPOINT, UPDATE_USER_DETAIL } = config.api_endpoint;
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [email, setEmail] = useState([]);
  const [loading, setLoading] = useState(false);

  const CallPageLoadAPI = async () => {
    const userData = JSON.parse(localStorage.getItem("current_user"));
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .get(
        GET_USER_ALT_ENDPOINT,
        {
          uid: userData.sub,
        },
        token
      )
      .then((res) => {
        console.log(res);
        if (res.status_code === 200) {
          setEmail(res.data.notification_config);
        }
        setLoading(false);
      });
  };

  const onChangeEmail = async () => {
    setLoading(true);
    const userData = JSON.parse(localStorage.getItem("current_user"));
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .post(
        UPDATE_USER_DETAIL,
        JSON.stringify({
          user_id: userData.sub,
          action: "notification_update",
          notification_config: email.find((i) => i === "email")
            ? ["sms"]
            : ["sms", "email"],
        }),
        token
      )
      .then((res) => {
        console.log(res);
        if (res.status_code === 200) {
          CallPageLoadAPI();
        } else {
          alert(res.message);
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    CallPageLoadAPI();
  }, []);

  return (
    <div className="ContactUs_main_box">
      {isMobile ? (
        <MobileHeaderFiltered
          title={"Notification"}
          route={MORE_ROUTE}
          icons={[]}
        />
      ) : (
        <MobileHeaderFiltered
          title={"Notification"}
          route={MORE_ROUTE}
          icons={[]}
        />
      )}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <>
          <div className="notification_item_main_box">
            <div className="notification_item_title">Push notification</div>
            <div>
              <Switch disabled checked />
            </div>
          </div>
          <div className="notification_item_main_box">
            <div className="notification_item_title">Email notification</div>
            <div>
              <Switch
                defaultChecked={email.find((i) => i === "email") ? true : false}
                onChange={onChangeEmail}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { getCurrentLoggedInSessionTokenWithPromise, getCurrentLoggedInUserWithPromise } from "../../../../config/userPool";
import api from "../../../../config/api";

export const FormContext = createContext()
const FormProvider = (props) => {
    const { children } = props
    // Form values state as a plain object.
    // Individual field will control its value via values' property.
    const [values, setValues] = useState({})

    // touched = submit button has been clicked
    const [touched, setTouched] = useState(false);

    // Set errors state for each of the form fields.
    // This works in conjunction of the evaluateValidity hook
    const [errors, setErrors] = useState([])

    // Each API requires token. The existing application somehow retrieves user token
    // everytime an API is being called. This is not efficient.
    // TODO: 1. Store user token in memory; 2. Get user token via request interceptor.

    const [token, setToken] = useState('')
    const [currentUser, setCurrentUser] = useState(null)
    const currentUserFromStorage = JSON.parse(
        localStorage.getItem("current_user") || 'null'
    )
    const [userData, setUserData] = useState({ data: currentUserFromStorage })

    // record detail from the API
    const [recordDetail, setRecordDetail] = useState(null)

    // form configuration from the API
    const [formConfig, setFormConfig] = useState(null)

    // Construct user permissions from user data
    const userPermissions = useMemo(() => {
        return userData?.data?.permissions || [];
    }, [userData]);

    // Construct user groups from user data
    const userGroups = useMemo(() => {
        const { user_group = [] } = userData?.data || {};
        return user_group.map((g) => g.user_group_name);
    }, [userData]);

    useEffect(() => {
        let userConfigLoaded = false

        const loadUserConfiguration = async () => {
            try {
                // Retrieve user configurations
                const _token = await getCurrentLoggedInSessionTokenWithPromise()
                setToken(_token);

                const _currentUser = await getCurrentLoggedInUserWithPromise()
                setCurrentUser(_currentUser);

                const _userData = await api.get(
                    "/api-users/getUser",
                    {
                        uid: _currentUser["cognito:username"],
                    },
                    _token
                );
                setUserData(_userData)
            } catch {
                console.debug('Failed retrieving user configuration')
            }
        }

        if (!token && !currentUser && !userConfigLoaded) {
            loadUserConfiguration()
        }

        // Clean up effect
        return () => {
            userConfigLoaded = true
        }
    }, [currentUser, userData, token])

    return (
        <FormContext.Provider value={{
            // Pass values and user configurations down to consumer
            state: [values, setValues],
            recordState: [recordDetail, setRecordDetail],
            configState: [formConfig, setFormConfig],
            touchedState: [touched, setTouched],
            errorsState: [errors, setErrors],
            token,
            currentUser,
            userData,
            userPermissions,
            userGroups,
        }}>
            {children}
        </FormContext.Provider>
    )
}

export default FormProvider
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import cx from 'classnames';
import evaluateVisibility from "../../utils/evaluateVisibility";
import "./index.css";
import { Loader } from "@progress/kendo-react-indicators";
import { FormContext } from "../../../context/form";
import UploadIcon from "../../../../../../assets/images/OHS/upload.svg";
import DeleteIcon from "../../../../../../assets/images/Work Declaration/delete.svg";
import api from "../../../../../../config/api";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../../../../config/userPool";
import ContentModal from "../../../ContentModal";
import FieldLabel from "../../../FieldLabel";
import { FileUploadSettings } from "./FileUploadSettings";
import { Text } from "../Text";
import styled from "styled-components";
import { useNode } from "@craftjs/core";


const StyledFileUpload = styled.div`
  margin: ${({ margin = [] }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
`;

//   "image/jpeg",
//   "image/png",
//   "image/jpg",
//   "application/pdf",
//   "application/msword",
// "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
// "application/vnd.ms-excel",
// "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
// "application/vnd.openxmlformats-officedocument.presentationml.presentation",
export const FileUpload = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const FileRef = useRef();
  // const { OHS_FILE_ENDPOINT, OHS_FILE_UPLOAD } = config.api_endpoint;
  const [fileUpload, setFileUpload] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { state, recordState, userPermissions = [] } = useContext(FormContext);
  const [recordDetail] = recordState
  const [values, setValues] = state;
  const {
    margin,
    textComponent,
    label: text,
    visibility = [],
    label,
    input_accept,
    id,
    disabled,
    required,
  } = props;
  const { isVisible } = evaluateVisibility({ values, visibility, recordDetail });
  const selected = useMemo(
    () => (values.ohs && values.ohs[props.tabID - 1]?.[id]) || [],
    [values, id]
  );
  const uploadFile = () => {
    FileRef.current.click();
  };
  const onUploadFile = async (e) => {
    // if (values[id] === undefined) {
    //   setValues({
    //     ...values,
    //     [id]: [e.target.files[0]],
    //   });
    // } else {
    //   values[id].push(e.target.files[0]);
    //   setValues({
    //     ...values,
    //   });
    // }
    let dataUrl = "";
    setFileUpload(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const data = e.target.files[0];
    const formData = new FormData();
    const fileType = data.type.toLowerCase();
    let attachType =
      fileType.indexOf("image") === 0
        ? "image"
        : fileType.indexOf("video") === 0
          ? "video"
          : "file";

    await api
      .post(
        "/api-ohs/ohs/upload_url",
        JSON.stringify({
          attach_type: attachType,
          filename: data.name,
        }),
        token
      )
      .then(async (res) => {
        const formDataUpload = new FormData();
        for (let k in res.data.payload) {
          formDataUpload.append(k, res.data.payload[k]);
        }
        formDataUpload.append("file", data);
        await api
          .postDiff(res.data.bucket_url, formDataUpload)
          .then(async (res1) => {
            if (res1 === undefined) {
              dataUrl = res.data.url;
              // api
              //   .post(
              //     OHS_FILE_ENDPOINT,
              //     JSON.stringify({
              //       filename: res.data.url.split("/").slice(-2).join("/"),
              //     }),
              //     token
              //   )
              //   .then((res2) => {
              //     setFileUpload(false);
              //     setShowPopup(false);
              //   });
              if (values.ohs) {
                if (props.tabID >= 1) {
                  if (values.ohs.length < props.tabID) {
                    setValues({
                      ...values,
                      ohs: [...values.ohs, { [id]: [...selected, dataUrl] }],
                    });
                  } else {
                    if (values.ohs[props.tabID - 1]?.[id]) {
                      let update = values.ohs[props.tabID - 1];
                      update[id] = [...selected, dataUrl];
                      setValues({
                        ...values,
                      });
                    } else {
                      values.ohs[props.tabID - 1][id] = [...selected, dataUrl];
                      setValues({
                        ...values,
                      });
                    }
                  }
                }
              } else {
                if (props.tabID > 0) {
                  setValues({
                    ...values,
                    ohs: [
                      {
                        [id]: [...selected, dataUrl],
                      },
                    ],
                  });
                } else {
                  setValues({
                    ...values,
                    [id]: [...selected, dataUrl],
                  });
                }
                // }
              }
              setFileUpload(false);
              setShowPopup(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    // if (!selected.find((i) => i === e.target.files[0])) {
    //   if(values.ohs){
    //     if(props.tabID >= 1){
    //       if(values.ohs.length < props.tabID){
    //       }else{
    //         if(values.ohs[props.tabID - 1]?.[id]){
    //         }else{

    //         }
    //       }
    //     }
    //   }else{
    //     if(props.tabID > 0){}
    //   }
    //   values.ohs[props.tabID - 1][id] = [...selected, e.target.files[0]];
    //   setValues({
    //     ...values,
    //     // [id]: [
    //     //   ...selected,
    //     //   e.target.value,
    //     // ]
    //   });
    // }
  };
  const onDeleteFile = (ids) => {
    // const deleted = values[id].filter((o, i) => ids !== i);
    // setValues({
    //   ...values,
    //   [id]: deleted,
    // });
    values.ohs[props.tabID - 1][id] = selected.filter((i) => i !== ids);
    setValues({
      ...values,
    });
  };

  const getFileName = filePath => {
    return filePath.split("/")
      .pop().split("?")[0]
      .split(".")
  }

  const canDownload = useMemo(() => {
    return userPermissions.some(p => [
      'download_ohs_files',
      'download_ohs_files_limited',
    ].includes(p))
  }, [userPermissions])

  useEffect(() => {
    if (required && isVisible()) {
      const cases =
        values.ohs === undefined
          ? true
          : values.ohs[props.tabID - 1] &&
            Array.isArray(values.ohs[props.tabID - 1][id])
            ? values.ohs[props.tabID - 1][id]?.length === 0
              ? true
              : false
            : true;
      if (cases === true) {
        props.checkValidation(true, `${id} ${props.tabID}`);
      } else {
        props.checkValidation(false, `${id} ${props.tabID}`);
      }
    }
  });
  return isVisible() ? (
    <StyledFileUpload
      ref={connect}
      className={cx([
        'w-full detail-item-box field-validation__wrapper',
      ])}
      margin={margin}
    >
      <FieldLabel {...textComponent} text={text} required={required} />
      <div
        onClick={() => {
          //   AddAttachment("MANDATORY", data.id, i.id);
          uploadFile();
        }}
        className={`
          OHS_Upload_main_box 
          ${disabled ? 'opacity-50 pointer-events-none' : ''}
        `}
        onMouseEnter={() => {
          setShowPopup(true);
        }}
        onMouseLeave={() => {
          setShowPopup(false);
        }}
      >
        <div>Upload {label}</div>
        <div>
          <input
            accept={input_accept}
            ref={FileRef}
            type="file"
            hidden
            onClick={(e) => (e.target.value = "")}
            onChange={(e) => onUploadFile(e)}
          />
          <>
            {fileUpload ? (
              <>
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  {showPopup && (
                    <div className="OHS_popup_main_box">
                      <div className="OHS_popup_text_box">
                        File is uploading. Please wait!
                      </div>
                      <div className="OHS_popup_tips" />
                    </div>
                  )}
                  <Loader />
                </div>
              </>
            ) : (
              <span
                className="form-upload-btn"
                style={{
                  cursor: "pointer",
                }}
              >
                <img src={UploadIcon} />
              </span>
            )}
          </>
        </div>
      </div>
      {
        required
          ? values.ohs === undefined ? (
            <div className="OHS_FORM_validation">This field is required!</div>
          ) : values.ohs?.[props.tabID - 1] &&
            Array.isArray(values.ohs?.[props.tabID - 1]?.[id]) ? (
            values.ohs?.[props.tabID - 1]?.[id]?.length === 0 ? (
              <div className="OHS_FORM_validation">This field is required!</div>
            ) : (
              false
            )
          ) : (
            <div className="OHS_FORM_validation">This field is required!</div>
          )
          : null
      }
      <div>
        {values.ohs &&
          Array.isArray(values.ohs[props.tabID - 1]?.[id]) &&
          values.ohs[props.tabID - 1]?.[id].map((i, id1) => (
            <div className="File_upload_detail_main_box lg:flex lg:justify-between lg:items-center !h-auto" key={id1}>
              <div className="lg:flex gap-[20px] items-center">
                {
                  // Show thumbnail if the file is an image;
                  // otherwise, show only the file extension
                  i.match(/\/images\//)
                    ? (
                      <div className="overflow-hidden flex justify-center max-w-[240px]">
                        <ContentModal
                          renderContent={() => (
                            <img src={i} className="max-w-[90vw] max-h-[90vh] max-h-[calc(.9_*_var(--doc-height))]" />
                          )}
                        >
                          <img src={i} className="max-h-[120px]" />
                        </ContentModal>
                      </div>
                    )
                    : (
                      <div className="File_type_box">
                        {
                          getFileName(i).pop()
                        }
                      </div>
                    )
                }
                <div className="File_detail_main_box">
                  <div className="File_detail_name">
                    {
                      getFileName(i).shift()
                    }
                  </div>
                </div>
              </div>
              {
                disabled
                  ? canDownload
                    ? <a href={i} class="Work_download_button cursor-pointer no-underline	hover:underline">Download</a>
                    : null
                  : (
                    <div
                      className="File_detail_delete"
                      onClick={() => onDeleteFile(i)}
                    >
                      <img src={DeleteIcon} />
                    </div>
                  )
              }
            </div>
          ))}
      </div>
    </StyledFileUpload>
  ) : null;
};

FileUpload.craft = {
  displayName: 'File Upload',
  props: {
    margin: ['5', '0', '5', '0'],
    id: '',
    text: 'Field label',
    textComponent: {
      ...Text.craft.props,
    },
  },
  related: {
    toolbar: FileUploadSettings,
  },
};